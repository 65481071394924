import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import Api from "src/api";
import { ProjectCardType } from "src/types";
import ProjectCard from "src/components/projectCard";
import { Grid, Typography } from "@material-ui/core";
import { Container } from "@material-ui/core";
import NewProjectButton from "../../components/NewProjectButton";

interface ProjectsProps extends RouteComponentProps {}

const Projects: React.FC<ProjectsProps> = () => {
  const [data, setData] = React.useState<ProjectCardType[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      let res;
      try {
        res = await Api.getProjectsPreviews();
      } catch (e) {
        setLoading(false);
        return;
      }
      setData(res?.data);
      setLoading(false);
    };
    fetchData();
  }, []);

  const renderEmptyScreen = () => {
    return (
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography color="textPrimary" variant="h3">
            У тебя еще нет проектов :(
          </Typography>
        </Grid>
        <Grid item color="textPrimary">
          <Typography>Создай новый!</Typography>
        </Grid>
        <Grid item>
          <NewProjectButton />
        </Grid>
      </Grid>
    );
  };

  const renderList = () => {
    return data.map((curr, i) => (
      <Grid item xs={12} md={6} key={i}>
        <ProjectCard key={curr.id} card={curr} />
      </Grid>
    ));
  };

  if (!isLoading && !data?.length) {
    return (
      <Container style={{ margin: "5% auto" }}>{renderEmptyScreen()}</Container>
    );
  }

  return (
    <Container>
      <Grid container direction="row" justify="space-between" spacing={4}>
        {renderList()}
      </Grid>
    </Container>
  );
};

export default Projects;
