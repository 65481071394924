import React, { Component } from "react";
import { RepositoryItem } from "../../types";
import {
  Typography,
  Button,
  Grid,
  withStyles,
  createStyles,
} from "@material-ui/core";
import Api from "src/api";
import { Field, FieldInputProps } from "react-final-form";

type RepositoryListProps = {
  list: RepositoryItem[];
  classes: any;
  chooseRep: (data: { cloneUrl: string; id: string }) => void;
};

class RepositoryList extends Component<RepositoryListProps, any> {
  handleClick = async (
    item: RepositoryItem,
    inputProps: FieldInputProps<string>
  ) => {
    inputProps.onChange(item.cloneUrl);
    window.localStorage.setItem("htmlUrl", item.htmlUrl);
    const res = await Api.getRepositoryBranches(item.id);
    window.localStorage.setItem("branches", JSON.stringify(res.data));
    this.props.chooseRep({ cloneUrl: item.cloneUrl, id: item.id });
  };

  private renderItem = (
    item: RepositoryItem,
    inputProps: FieldInputProps<string>,
    i: number
  ) => {
    return (
      <Grid item key={i}>
        <Button
          color={item.cloneUrl === inputProps.value ? "primary" : "default"}
          variant="outlined"
          fullWidth
          size="medium"
          key={item.name}
          onClick={async () => this.handleClick(item, inputProps)}
          startIcon={<Typography align="left">{i}</Typography>}
          classes={this.props.classes}
        >
          <Typography>{`${item.name} ${item.cloneUrl}`}</Typography>
        </Button>
      </Grid>
    );
  };

  fieldComponent = ({ input }: { input: FieldInputProps<string> }) => {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5">Выбери репозиторий</Typography>
        </Grid>
        {this.props.list.map((curr, i) => this.renderItem(curr, input, i))}
      </Grid>
    );
  };

  public render() {
    return (
      <Field name="repositoryCloneGitUrl" component={this.fieldComponent} />
    );
  }
}

const useStyles = createStyles({
  root: {
    textTransform: "none",
    "& .MuiButton-label": {
      justifyContent: "unset",
    },
  },
});

export default withStyles(useStyles)(RepositoryList);
