import React from "react";
import { RouteComponentProps } from "@reach/router";
import ProjectHeader from "../../components/projectHeader";
import { ProjectType } from "src/types";
import Api from "../../api";
import { Container } from "@material-ui/core";

interface ProjectProps extends RouteComponentProps<{ id: number }> {}

const ProjectWrapper: React.FC<ProjectProps> = (props) => {
  const [data, setData] = React.useState<ProjectType>();

  const getBranches = async (gitRepositoryId: string) => {
    const res = await Api.getRepositoryBranches(gitRepositoryId);
    window.localStorage.setItem("branches", JSON.stringify(res?.data || []));
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await Api.getProject(props.id);
      setData(res.data);
      return res.data;
    };
    fetchData().then((curr) => {
      getBranches(curr.gitRepositoryId).catch((e) => console.error(e));
    });
  }, [props.id]); // Перезапускать эффект только если id поменялся

  if (!data) return null;

  return (
    <Container>
      <ProjectHeader {...data} />
      {props.children}
    </Container>
  );
};

export default ProjectWrapper;
