import * as React from "react";
import { TextField } from "@material-ui/core";
import { Field, FieldRenderProps } from "react-final-form";
import FieldWrapper from "../../components/fieldWrapper";
import { FrontUpErrors } from "../../types";

type Props = {
  label: string;
  name: string;
  caption?: string;
  placeholder: string;
  formErrors?: FrontUpErrors;
};

const TextFieldAdapter: React.FC<FieldRenderProps<Props>> = (props) => {
  const { input, meta, formErrors, ...rest } = props;
  const submitError = formErrors?.options
    ? formErrors.options[input.name]
    : null;
  const error = meta.touched && (meta?.error || submitError);
  return (
    <FieldWrapper label={props.label} caption={props.caption}>
      <TextField
        {...input}
        {...rest}
        onChange={(ev) => props.input.onChange(ev.currentTarget.value)}
        variant="outlined"
        color="primary"
        error={error}
        helperText={error}
      />
    </FieldWrapper>
  );
};

const NewField = (props: Props) => {
  return (
    <Field
      name={props.name}
      component={TextFieldAdapter}
      placeholder={props.placeholder}
      label={props.label}
      caption={props.caption}
      formErrors={props.formErrors}
    />
  );
};

export default NewField;