import * as React from "react";
import AppRouter from "src/appRouter";
import Header from "src/components/Header";
import { connect, ConnectedProps } from "react-redux";
import { actions as userActions } from "src/store/User";

import "./App.css";

import { navigate, RouteComponentProps } from "@reach/router";
import { RootState } from "./store";

interface Props extends PropsFromRedux, RouteComponentProps {}

class App extends React.Component<Props> {
  public timer?: number;

  public async componentDidMount() {
    const isLoggin = this.props.getUser();
    this.timer = window.setInterval(() => {
      this.props.getUser();
    }, 1000 * 60);

    if (!this.props.user || !isLoggin) {
      await navigate("/login");
    }
  }

  public async componentDidUpdate() {
    if (!this.timer && !this.props.user) {
      this.timer = window.setInterval(() => {
        this.props.getUser();
      }, 1000 * 60);
    }
  }

  public componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  public render() {
    return (
      <div className="App" style={{ height: "100%" }}>
        <div style={{ height: "100%" }}>
          <div style={{ margin: "1% 0px 2% 0" }}>
            <Header />
          </div>
          <div style={{ flex: 1 }}>
            <AppRouter />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.userStore.user,
});

const mapActionsToProps = {
  getUser: userActions.getUser,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(App);
