import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { store } from "src/store";
import Api from "./Api";

let httpClient: AxiosInstance;

httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

httpClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    console.error("request error", error.message);
    if (error.response?.status === 401) {
      store.dispatch({ type: "LOGOUT" });
    }

    const er = { ...error };
    return Promise.reject(er.response.data);
  }
);

const Service = new Api(httpClient);

export default Service;
