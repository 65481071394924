import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import BuildsList from "./buildsList";
import ProjectSettings from "./projectSettings";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
    value: index,
  };
}

const ProjectNav: React.FC<any> = (props: { id: number }) => {
  const [value, setValue] = React.useState("one");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  const classes = useStyles();

  return (
    <div>
      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="primary"
          variant="standard"
          aria-label="full width tabs example"
          classes={{ root: classes.tabsRoot, indicator: classes.indicator }}
        >
          <Tab label="Builds" {...a11yProps("one")} />
          <Tab label="Settings" {...a11yProps("two")} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index="one">
        <BuildsList projectId={props.id} />
      </TabPanel>
      <TabPanel value={value} index="two">
        <ProjectSettings id={props.id}></ProjectSettings>
      </TabPanel>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  appBar: {
    width: "50%",
    borderBottom: "1px solid #CFCFCF",
    boxShadow: "none",
  },
  tabsRoot: {
    width: "100%",
  },
  indicator: {
    height: "4px",
  },
}));

export default ProjectNav;
