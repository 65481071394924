import * as React from "react";
import Api from "src/api";
import { BuildPreview } from "src/types";
import { CardContent, Grid, Typography } from "@material-ui/core";
import Card from "src/components/Card";
import Link from "../Link";
import { BUILD_STATUSES_NAMES } from "../../constants";

interface BuildsListProps {
  projectId: number;
}

const BuildsList: React.FC<BuildsListProps> = (props) => {
  const [data, setData] = React.useState<BuildPreview[]>([]);

  React.useEffect(() => {
    const getData = () =>
      Api.getProjectsBuilds(props.projectId)
        .then((res) => setData(res.data))
        .catch((e) => console.error(e));

    getData();
    const timer = window.setInterval(getData, 5000);
    return () => {
      window.clearInterval(timer);
    };
  }, [props.projectId]);

  const renderBuild = (build: BuildPreview) => {
    return (
      <Grid item key={build.buildId} xs={12} md={6}>
        <Link to={`/project/${props.projectId}/${build.buildId}`}>
          <Card variant="outlined" color="primary">
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Build Id: {build.buildId}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="subtitle2">
                    Статус: {BUILD_STATUSES_NAMES[build.status]}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="subtitle2">
                    Commit hash: {build.commitHash?.slice(0, 7) + "..."}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography variant="subtitle2">
                    Дата: {new Date(build.creationDate).toLocaleString()}
                  </Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <Typography variant="subtitle2">
                    Сообщение: {build.commitMessage}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Link>
      </Grid>
    );
  };

  return (
    <Grid container direction="column" spacing={2}>
      {data.sort((a, b) => b.buildId - a.buildId).map(renderBuild)}
    </Grid>
  );
};

export default BuildsList;
