import * as React from "react";
import { Grid, Typography } from "@material-ui/core";
import { ProjectType } from "src/types";
import Button from "../Button";
import { navigate } from "@reach/router";
import Api from "src/api";

interface Props {
  id: ProjectType["id"];
  name: ProjectType["name"];
  link: ProjectType["link"];
  gitUrl: ProjectType["gitUrl"];
  htmlUrl: ProjectType["htmlUrl"];
  domainName: ProjectType["domainName"];
  customDomainName?: ProjectType["customDomainName"];
}

const ProjectHeader: React.FC<Props> = (props) => {
  const onClick = async () => {
    const newBuildRes = await Api.runRebuild(props.id);
    if (!newBuildRes.data) return;
    await navigate(`/project/${props.id}/${newBuildRes.data.buildId}`);
  };

  const links = [
    props.customDomainName ? `https://${props.customDomainName}` : null,
    `https://${props.domainName}`,
  ].filter(Boolean);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <a href={`${window.location.origin}/project/${props.id}`}>
          <Typography variant="h4">{props.name}</Typography>
        </a>
        {links.map((link) => (
          <>
            <br />
            <a href={link} target="_blank">
              <Typography variant="caption">{link}</Typography>
            </a>
          </>
        ))}
        <br />

        <a href={props.htmlUrl} target="_blank">
          <Typography variant="caption">{props.htmlUrl}</Typography>
        </a>
      </Grid>
      <Grid item xs={12} md={4}>
        <Button variant="outlined" color="secondary" onClick={onClick}>
          Развернуть последний коммит
        </Button>
      </Grid>
    </Grid>
  );
};

export default ProjectHeader;
