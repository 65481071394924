import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button, { ButtonProps } from "@material-ui/core/Button";

type Props = ButtonProps & { classes?: string };

const useStyles = (props: Props) =>
  makeStyles((theme) => ({
    root: {
      textTransform: "none",
      borderRadius: 8,
      padding: "15px 43px",
      "&:hover": {
        // @ts-ignore
        backgroundColor: theme.palette[props.color || "primary"].main,
        // @ts-ignore
        color: theme.palette[props.color || "primary"].contrastText,
      },
    },
  }));

function ButtonComponent(props: Props) {
  const classes = useStyles(props)();
  return (
    <Button className={[classes.root, props.classes].join(" ")} {...props}>
      {props.children}
    </Button>
  );
}

export default ButtonComponent;
