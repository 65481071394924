import { Build as BuildType, ProjectType } from "../../types";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Theme,
} from "@material-ui/core";
import {
  BUILD_FIELDS_NAMES,
  PROJECT_FIELDS_NAMES,
  BUILD_STATUSES_NAMES,
} from "../../constants";
import CancelBuildButton from "./cancelBuildButton";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "./ListItem";

type Props = {
  build: BuildType;
  buildId: string;
  projectId: string;
};

const getBorderColorByBuildResult = (
  status: BuildType["status"],
  theme: Theme
): string => {
  switch (status) {
    case "DONE":
      return theme.palette.success.light;
    case "REJECTED":
      return theme.palette.error.light;
    default:
      return "";
  }
};

function getMakeStyles(props: Props) {
  return makeStyles((theme) => {
    const borderColor = getBorderColorByBuildResult(props.build.status, theme);

    return {
      welcomeCard: {
        border: "2px solid",
        borderColor: borderColor,
        boxShadow: "none",
      },
    };
  });
}

export default function BuildHeader(props: Props) {
  const build = props.build;
  const buildParams = props.build.buildConfig;

  const useStyles = (props: Props) => getMakeStyles(props);

  const classes = useStyles(props)();

  return (
    <Card className={classes.welcomeCard}>
      <CardHeader title={`Build id: ${props.buildId}`} />
      <CardContent>
        <Grid container>
          <ListItem>Статус: {BUILD_STATUSES_NAMES[build.status]}</ListItem>
          <ListItem>
            {BUILD_FIELDS_NAMES.commitHash}:{" "}
            {build.commitHash?.slice(0, 7) + "..."}
          </ListItem>
          <ListItem>
            Дата: {new Date(build.creationDate).toLocaleString()}
          </ListItem>
          <ListItem>
            {PROJECT_FIELDS_NAMES.gitBranch}: {buildParams.gitBranch}
          </ListItem>
          <ListItem>
            {BUILD_FIELDS_NAMES.pathToBuild}: {buildParams.pathToBuild}
          </ListItem>
          <ListItem hide={!buildParams.buildCommand}>
            {BUILD_FIELDS_NAMES.buildCommand}: {buildParams.buildCommand}
          </ListItem>
          <ListItem hide={!buildParams.nodeVersion}>
            {BUILD_FIELDS_NAMES.nodeVersion}: {buildParams.nodeVersion}
          </ListItem>
        </Grid>
      </CardContent>
      <CardActions>
        <CancelBuildButton
          buildId={props.buildId}
          projectId={props.projectId}
          buildStatus={props.build.status}
        />
      </CardActions>
    </Card>
  );
}
