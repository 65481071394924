import { User } from "src/types";
import { Action, Dispatch } from "./StoreTypes";
import Api from "src/api";
import { navigate } from "@reach/router";

const USER_CONSTANTS = {
  LOGIN_COMPLETE: "LOGIN_FETCHED",
  LOGOUT: "LOGOUT",
  LOGIN_FETCHING: "LOGIN_FETCHING",
  LOGIN_FAILED: "LOGIN_FAILED",
  DATA_FETCHING: "USERS_FETCHING",
  DATA_FETCHED: "USERS_FETCHED",
  DATA_FETCH_FAILED: "USERS_FETCH_FAILED",
  GET_CURR_USER: "USERS_GET_CURR_USER",
  UPDATE_USER: "USERS_UPDATE_USER",
};

export type FetchConstants = {
  DATA_FETCHING: string;
  DATA_FETCHED: string;
  DATA_FETCH_FAILED: string;
  GET_USER: string;
};

export const actions = {
  completeLogIn: (payload: User) => {
    return {
      type: USER_CONSTANTS.LOGIN_COMPLETE,
      payload,
    };
  },
  getUser() {
    return async (dispatch: Dispatch<User>) => {
      let isLoginSuccess = false;
      dispatch({ type: USER_CONSTANTS.GET_CURR_USER });
      try {
        const response = await Api.getUser();
        dispatch(actions.completeLogIn(response.data));
        isLoginSuccess = true;
        console.warn("GET_USER");
        return isLoginSuccess;
      } catch (e) {
        console.error("Error: ", e);
        return isLoginSuccess;
      }
    };
  },
  login: (email: string, password: string, token?: string) => {
    return async (dispatch: Dispatch<User>) => {
      dispatch({ type: USER_CONSTANTS.LOGIN_FETCHING });
      let isLoginSuccess = false;
      try {
        const res = await Api.getUser();
        dispatch(actions.completeLogIn(res.data));
        isLoginSuccess = true;
      } catch (e) {
        dispatch({
          type: USER_CONSTANTS.LOGIN_FAILED,
        });
        isLoginSuccess = false;
      }
      return isLoginSuccess;
    };
  },
  logout: () => {
    return async (dispatch: Dispatch<User>) => {
      try {
        await Api.logout();
        await navigate("/login");
        dispatch({ type: USER_CONSTANTS.LOGOUT });
      } catch (e) {
        console.error(e);
      }
    };
  },
};

// Reducer
export const defaultUserState: UserState = {
  fetching: false,
  user: null,
};

export type UserState = {
  user: User | void;
  fetching: boolean;
};

export default function (
  state: UserState = defaultUserState,
  action: Action<User>
): UserState {
  switch (action.type) {
    case USER_CONSTANTS.LOGIN_FETCHING:
      return { ...state, fetching: true, user: null };
    case USER_CONSTANTS.LOGIN_COMPLETE:
      return {
        ...state,
        fetching: false,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case USER_CONSTANTS.LOGIN_FAILED:
      return { ...state, fetching: false, user: undefined };
    case USER_CONSTANTS.LOGOUT:
      return { ...state, user: null };
    default:
      return state;
  }
}
