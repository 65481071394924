export const BUILD_STATUES = {
  NEW: "NEW",
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
  // by internal logic
  REJECTED: "REJECTED",
  // by user
  CANCELED: "CANCELED",
  FAILED: "FAILED",
};

export const ENDED_BUILD_STATUSES = [
  BUILD_STATUES.DONE,
  BUILD_STATUES.CANCELED,
  BUILD_STATUES.REJECTED,
  BUILD_STATUES.FAILED,
];

export const BUILD_STATUSES_NAMES = {
  [BUILD_STATUES.NEW]: "Старт",
  [BUILD_STATUES.IN_PROGRESS]: "В процессе",
  [BUILD_STATUES.DONE]: "Успешно завершен",
  [BUILD_STATUES.REJECTED]: "Отменен следующим билдом",
  [BUILD_STATUES.CANCELED]: "Отменен пользователем",
  [BUILD_STATUES.FAILED]: "Ошибка",
};

export const ERRORS_TYPES = {
  PROJECT_LIMIT: "PROJECT_LIMIT",
  VALIDATION_ERROR: "VALIDATION_ERROR",
};

export const PROJECT_TYPES = {
  NODE: "NODEJS",
  STATIC_SITE: "STATIC_SITE",
};

export const PROJECT_TYPES_STRINGS = {
  [PROJECT_TYPES.NODE]: "билд с помощью NODE.JS",
  [PROJECT_TYPES.STATIC_SITE]: "статический сайт",
};

export const PROJECT_TYPE_NAMES: {
  "билд с помощью NODE.JS": "NODEJS";
  "статический сайт": "STATIC_SITE";
} = {
  // @ts-ignore
  "билд с помощью NODE.JS": PROJECT_TYPES.NODE,
  // @ts-ignore
  "статический сайт": PROJECT_TYPES.STATIC_SITE,
};

export const PROJECT_FIELDS_NAMES = {
  name: "name",
  domainName: "доменное имя",
  gitBranch: "branch",
  buildCommand: "build command",
  pathToBuild: "output directory",
  environments: "переменные окружения",
  nodeVersion: "node version",
};

export const BUILD_FIELDS_NAMES = {
  status: "статус",
  gitBranch: "branch",
  buildCommand: PROJECT_FIELDS_NAMES.buildCommand,
  pathToBuild: PROJECT_FIELDS_NAMES.pathToBuild,
  environments: PROJECT_FIELDS_NAMES.environments,
  commitHash: "commit hash",
  nodeVersion: PROJECT_FIELDS_NAMES.nodeVersion,
};
