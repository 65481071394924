import * as React from "react";
import { Link } from "@reach/router";
import Card from "src/components/Card";
import { ProjectCardType } from "src/types";
import ProjectData from "./projectData";

type ProjectCardProps = {
  card: ProjectCardType;
};

const ProjectCard: React.FC<ProjectCardProps> = (props) => {
  const { card } = props;
  return (
    <Link to={`/project/${props.card.id}`}>
      <Card variant="outlined" color="primary">
        <ProjectData {...card} />
      </Card>
    </Link>
  );
};

export default ProjectCard;
