import React from "react";
import { Grid, Typography } from "@material-ui/core";

type Props = {
  hide?: boolean;
};

const ListItem: React.FC<Props> = (props) => {
  if (props.hide) return null;

  return (
    <Grid item md={6} xs={12}>
      <Typography variant="subtitle2">{props.children}</Typography>
    </Grid>
  );
};

export default ListItem;
