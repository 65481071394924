import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

type FieldWrapperProps = {
  label: string;
  caption?: string;
  alignItems?: string;
};

const FieldWrapper: React.FC<FieldWrapperProps> = (props) => {
  const classes = useStyles(props)();

  return (
    <Grid container direction="row" justify="space-between" spacing={2}>
      <Grid item xs={12} md={4}>
        <Grid container direction="column">
          <Grid item>
            <Typography>{props.label}</Typography>
          </Grid>
          <Grid item>
              {props?.caption?.split("<br/>")
                  ?.map(value => {
                      return <Typography variant="caption" display="block">{value}</Typography>
                  })}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7}>
        <div className={classes.field}>{props.children}</div>
      </Grid>
    </Grid>
  );
};

const useStyles = (props: FieldWrapperProps) =>
  makeStyles((theme) => ({
    field: {
      float: "right",
      width: "100%",
      "& > div": {
        width: "100%",
      },
    },
  }));

export default FieldWrapper;
