import React from "react";
import Api from "../../api";
import { navigate } from "@reach/router";
import ButtonComponent from "../../components/Button";
import { ENDED_BUILD_STATUSES } from "../../constants";

type Props = {
  buildId: string;
  projectId: string;
  buildStatus: string;
};

class CancelBuildButton extends React.Component<Props> {
  _cancelBuild = async () => {
    await Api.cancelBuild(this.props.projectId, this.props.buildId);
    await navigate(`/project/${this.props.projectId}`);
  };

  render() {
    if (ENDED_BUILD_STATUSES.includes(this.props.buildStatus)) return null;

    return (
      <ButtonComponent
        color="secondary"
        variant="outlined"
        onClick={this._cancelBuild}
      >
        Отменить билд
      </ButtonComponent>
    );
  }
}

export default CancelBuildButton;
