import * as React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { FieldRenderProps } from "react-final-form";
import FieldWrapper from "../../components/fieldWrapper";

type SelectFieldProps = { getValues: () => string[] };

const SelectComponent: React.FC<FieldRenderProps<SelectFieldProps>> = (
  props
) => {
  const { input, meta } = props;

  const selectId = `select-${props.name}`;
  const labelId = selectId + "-label";
  const values = props.getValues();
  const error = meta.touched && meta?.error;
  return (
    <FieldWrapper label={props.label} caption={props.caption}>
      <FormControl variant="outlined" error={error}>
        <InputLabel id={labelId}>{props.label}</InputLabel>
        <Select labelId={labelId} id={selectId} value={input.value} {...input}>
          {values.map(renderItem)}
        </Select>
        <FormHelperText id={selectId + "helper"}>{error}</FormHelperText>
      </FormControl>
    </FieldWrapper>
  );
};

const renderItem = (value: string) => {
  return (
    <MenuItem key={value} value={value}>
      {value}
    </MenuItem>
  );
};

export default SelectComponent;
