import React, { Component } from "react";
import ChooseRepository from "src/fields/сhooseRepository";
import Api from "src/api";
import { navigate } from "@reach/router";
import { SecondPage } from "./secondPage";
import { Form, FormRenderProps } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { formValidation } from "../validators";
import { ProjectType } from "../../types";
import { PROJECT_TYPE_NAMES } from "../../constants";

type CreateProjectFormProps = {
  //
};

export type FormValues = {
  repositoryLink: string;
  name: string;
  buildCommand?: string;
  pathToBuild?: string;
  repositoryCloneGitUrl: string;
  gitBranch: string;
  nodeVersion?: string;
  projectType: keyof typeof PROJECT_TYPE_NAMES;
  environments: { key: string; value: string }[];
};

type S = {
  repositoryCloneGitUrl: string;
  gitRepositoryId: string;
};

class CreateProjectForm extends Component<CreateProjectFormProps, S> {
  state = {
    repositoryCloneGitUrl: "",
    gitRepositoryId: "",
  };

  handleSubmit = async (values: FormValues) => {
    try {
      const projectType: ProjectType["projectType"] = this.getProjectType(
        values.buildCommand
      );

      const data = {
        name: values.name,
        gitUrl: values.repositoryCloneGitUrl,
        htmlUrl: window.localStorage.getItem("htmlUrl"),
        buildCommand: values.buildCommand,
        pathToBuild: values.pathToBuild,
        gitBranch: values.gitBranch,
        gitRepositoryId: this.state.gitRepositoryId,
        projectType: projectType,
        nodeVersion: values.nodeVersion,
        environments: values.environments?.reduce(
          (obj, item) => ({ ...obj, [item.key]: item.value }),
          {}
        ),
      };

      const res = await Api.createProject(data);
      setTimeout(async () => await navigate(`/project/${res.data.id}`), 500);
    } catch (error) {
      return error;
    }
  };

  getProjectType = (buildCommand: string) => {
    if (buildCommand?.trim()) {
      return "NODEJS";
    }
    return "STATIC_SITE";
  };

  chooseRep = (data: { cloneUrl: string; id: string }) => {
    this.setState({
      repositoryCloneGitUrl: data.cloneUrl,
      gitRepositoryId: data.id,
    });
  };

  getPage = (formData: FormRenderProps<FormValues, {}>) => {
    if (this.state.repositoryCloneGitUrl)
      return (
        <SecondPage
          formData={formData}
          buttonText=""
          settings={{ renderName: true }}
        />
      );
    return <ChooseRepository chooseRep={this.chooseRep} />;
  };

  _validate = (values: FormValues) => {
    return formValidation.validateForm(values);
  };

  public render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        mutators={{ ...arrayMutators }}
        validate={this._validate}
      >
        {(formData: FormRenderProps<FormValues, {}>) => (
          <form onSubmit={formData.handleSubmit}>{this.getPage(formData)}</form>
        )}
      </Form>
    );
  }
}

export default CreateProjectForm;
