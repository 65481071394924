import { Validators, ValidationResult } from "@lemoncode/fonk";
import { createFinalFormValidation } from "@lemoncode/fonk-final-form";
import { charsNotBlackList } from "@lemoncode/fonk-chars-not-black-list-validator";
import { FormValues } from "./createProjectForm/createProjectForm";
import { ValidatorData, ValidationObject } from "./validatorTypes";

const getError = <T, Vls>(
  validators: ValidationObject<T, Vls>[],
  data: ValidatorData<T, Vls>
): ValidationResult => {
  const res = validators
    .map(
      (curr: ValidationObject<T, Vls>): ValidationResult =>
        curr.validator({
          ...data,
          customArgs: curr.customArgs,
          message: curr.message,
        })
    )
    .filter((curr) => !curr.succeeded);

  if (!res[0]) return;
  return res[0];
};

const requiredMessage = "Обязательное поле";

const pathToBuildValidators = [
  { validator: Validators.required.validator, message: requiredMessage },
];

const buildCommandValidators = [
  {
    validator: Validators.pattern.validator,
    customArgs: {
      pattern: /^npm run|^yarn.*/,
    },
    message:
      "Неправильно задана команда для сборки. Должна начинаться с: npm run или yarn",
  },
  {
    validator: charsNotBlackList.validator,
    customArgs: { blackListChars: "./|\\><,!@#$%^&*()+-=}{][|'\"." },
    message: "и никаких спецсимволов",
  },
];

const BuildCommandValidator = (
  data: ValidatorData<FormValues["buildCommand"], FormValues>
) => {
  return FieldValidator<FormValues["buildCommand"]>(
    data,
    "buildCommand",
    // @ts-ignore
    buildCommandValidators
  );
};

const PathToBuildValidator = (
  data: ValidatorData<FormValues["pathToBuild"], FormValues>
) => {
  // @ts-ignore
  return FieldValidator(data, "pathToBuild", pathToBuildValidators);
};

const FieldValidator = <T>(
  data: ValidatorData<T, FormValues>,
  field: keyof FormValues,
  validators: ValidationObject<T, FormValues>[]
) => {
  if (!data.values.buildCommand?.trim()) {
    return true;
  }
  const error = getError<T, FormValues>(validators, data);
  return (
    error || {
      key: field,
      succeeded: true,
    }
  );
};

const validationSchema = {
  field: {
    name: [
      {
        validator: Validators.pattern.validator,
        customArgs: {
          pattern: /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9])\\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\\-]*[A-Za-z0-9])$/,
        },
        message:
          "Имя не может содержать пробелы, специальные символы, подчеркивание, точку и русские буквы",
      },
      {
        validator: charsNotBlackList.validator,
        customArgs: { blackListChars: "." },
        message: "и никаких точек",
      },
    ],
    gitBranch: [
      {
        validator: Validators.required,
        message: requiredMessage,
      },
    ],
    buildCommand: [BuildCommandValidator],
    pathToBuild: [PathToBuildValidator],
  },
};

// @ts-ignore
export const formValidation = createFinalFormValidation(validationSchema);
