import isValidDomain from "is-valid-domain";
import { createFinalFormValidation } from "@lemoncode/fonk-final-form";
import { ValidationResult } from "@lemoncode/fonk";
import { FieldValidationFunctionSync } from "@lemoncode/fonk/typings/model/field-validator.model";

interface FieldValidatorArgs<CustomArgs> {
  value: any;
  values?: any;
  customArgs?: CustomArgs;
  message?: string | string[];
}

const DomainNameValidator: FieldValidationFunctionSync = (
  data: FieldValidatorArgs<{}>
): ValidationResult => {
  const isValid = isValidDomain(data.values.domainName, {
    subdomain: true,
    wildcard: false,
  });
  console.log("isValid", isValid, data.values);
  if (!isValid) {
    return {
      type: "domainName",
      succeeded: false,
      message: "Неверное имя домена",
    };
  }
  return {
    type: "domainName",
    succeeded: true,
    message: "",
  };
};

const validator: { validator: FieldValidationFunctionSync } = {
  validator: DomainNameValidator,
};

export default createFinalFormValidation({
  field: {
    domainName: [
      {
        validator: validator,
        message: "Неверное имя домена",
      },
    ],
  },
});
