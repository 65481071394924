import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import ReduxThunk from "redux-thunk";
import User from "./User";

const reducer = combineReducers({
  userStore: User,
});

export type RootState = ReturnType<typeof reducer>;

const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    blacklist: ["form"],
  },
  reducer
);

export const store = createStore(persistedReducer, applyMiddleware(ReduxThunk));

const persist = persistStore(store);

export default { store, persist };
