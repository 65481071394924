import * as React from "react";
import { RouteComponentProps } from "@reach/router";
import { RepositoryItem } from "src/types";
import Api from "src/api";
import RepositoryList from "./repositoryList";

interface ChooseRepositoryProps extends RouteComponentProps {
  chooseRep: (data: { cloneUrl: string; id: string }) => void;
}

const ChooseRepository: React.FC<ChooseRepositoryProps> = (props) => {
  const [data, setData] = React.useState<RepositoryItem[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await Api.getUserRepositoryList();
      setData(res.data);
    };
    fetchData();
  }, []);

  return <RepositoryList list={data} chooseRep={props.chooseRep} />;
};

export default ChooseRepository;
