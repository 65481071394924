import React from "react";
import Terminal, { LineType } from "react-terminal-ui";
import "./terminal.css";

const TerminalRenderer: React.FC<{ logs?: string }> = (props) => {
  const logs = props.logs || "";

  const transformedLogs = logs
    .split("\n")
    .filter(Boolean)
    .map((value) => ({ type: LineType.Input, value }));

  return (
    <Terminal name="front up" lineData={transformedLogs} onInput={() => {}} />
  );
};

export default TerminalRenderer;
