import * as React from "react";
import { Card as MuiCard } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CardProps } from "@material-ui/core/Card/Card";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "6px",
    "&:hover": {
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const Card: React.FC<CardProps> = (props: CardProps) => {
  const classes = useStyles();
  return (
    <MuiCard {...props} className={classes.card}>
      {props.children}
    </MuiCard>
  );
};

export default Card;
