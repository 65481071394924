import React from "react";
import { Container, Grid, Typography, useMediaQuery } from "@material-ui/core";
import classes from "./Header.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import LoginButton from "./LoginButton";
import NewButton from "src/components/NewProjectButton";
import LogoutButton from "./logout";

type HeaderProps = {};

const userFormState = (state: RootState) => state.userStore.user;

const Header: React.FC<HeaderProps> = () => {
  const user = useSelector(userFormState);
  const minWidth600px = useMediaQuery("(min-width:600px)");

  return (
    <Container>
      <header>
        <Logo renderShort={!!minWidth600px} />
        <Grid
          item
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Grid item>{user ? <NewButton /> : <div />}</Grid>
          <Grid item>
            <LoginButton />
          </Grid>
          <Grid item>
            <LogoutButton />
          </Grid>
          <Grid item>
            <Typography color="primary">
              {user && (user.name || user.gitHubName)}
            </Typography>
          </Grid>
          <Grid item>
            {minWidth600px ? (
              <a target="_blank" href="https://t.me/frontupchat">
                <Typography color="primary">Связаться с нами</Typography>
              </a>
            ) : null}
          </Grid>
        </Grid>
      </header>
    </Container>
  );
};

function Logo(props: { renderShort: boolean }) {
  const fullLogo = "/headerIcon.svg";
  const shortLogog = "/logo.svg";
  return (
    <a href="/">
      <div className={classes.logosWrapper}>
        <div>
          {props.renderShort ? (
            <img src={fullLogo} alt="Front Up" />
          ) : (
            <img src={shortLogog} alt="Front Up" />
          )}
        </div>
      </div>
    </a>
  );
}

export default Header;
