import * as React from "react";
import { Router } from "@reach/router";
import Project from "src/routes/project";
import CreateNewProject from "../routes/createNewProject";
import Projects from "src/routes/projects";
import Build from "src/routes/build";
import Login from "src/routes/login";

const AppRouter: React.FC<{}> = () => {
  return (
    <Router style={{ height: "100%" }}>
      <Projects path="/" />
      <Project path="/project/:id" />
      <Build path="/project/:projectId/:buildId" />
      <CreateNewProject path="/new-project" />
      <Login path="/login" />
    </Router>
  );
};

export default AppRouter;
