import React from "react";
import { RouteComponentProps } from "@reach/router";
import ProjectNav from "../../components/projectNav";
import ProjectWrapper from "../../components/projectWrapper";

interface ProjectProps extends RouteComponentProps<{ id: number }> {}

const Project: React.FC<ProjectProps> = (props) => {
  return (
    <ProjectWrapper id={props.id}>
      <ProjectNav id={props.id} />
    </ProjectWrapper>
  );
};

export default Project;
