import * as React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import Main from "./Main";

interface LoginPageProps extends RouteComponentProps {}

const Index: React.FC<LoginPageProps> = () => {
  const redirect = async () => {
    await navigate("/");
  };

  const userFormState = (state: RootState) => state.userStore.user;
  const user = useSelector(userFormState);
  if (user) {
    redirect().then();
    return null;
  }

  return <Main />;
};

export default Index;
