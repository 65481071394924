import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@material-ui/styles";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Store from "./store";
import ReactGA from "react-ga";

import theme from "./theme";

const RootWrapper: React.FC<{}> = (props) => {
  if (process.env.REACT_APP_GA_TAG) {
    ReactGA.initialize(process.env.REACT_APP_GA_TAG);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  return <div>{props.children}</div>;
};

ReactDOM.render(
  <React.StrictMode>
    <RootWrapper>
      <Provider store={Store.store}>
        <PersistGate loading={null} persistor={Store.persist}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </RootWrapper>
  </React.StrictMode>,
  document.getElementById("root")
);
