import React from "react";
import Button from "../../components/Button";
import { FieldArray } from "react-final-form-arrays";
import TextField from "../../fields/textField";
import { Grid } from "@material-ui/core";
import FieldWrapper from "../../components/fieldWrapper";

const CiVariables = (props: { name: string }) => {
  const renderField = (
    name: string,
    index: number,
    fields: { remove: (i: number) => void }
  ) => {
    return (
      <Grid container spacing={3} key={name}>
        <Grid item xs={8}>
          <Grid direction="column" container spacing={1}>
            <Grid item>
              <TextField
                name={`${name}.key`}
                placeholder="BACKEND_ADDRESS"
                label="name"
              />
            </Grid>
            <Grid item>
              <TextField
                name={`${name}.value`}
                placeholder="https://api.example.com/"
                label="value"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => removeField(fields, index)}
          >
            -
          </Button>
        </Grid>
      </Grid>
    );
  };

  const removeField = (
    fields: { remove: (i: number) => void },
    index: number
  ) => fields.remove(index);

  return (
    <FieldWrapper
      label="environment variables"
      caption="переменные окружения"
      alignItems="flex-start"
    >
      <FieldArray name={props.name}>
        {({ fields }) => (
          <div>
            {fields.map((item, index) => renderField(item, index, fields))}
            <Button
              variant="outlined"
              onClick={() => fields.push({ key: "", value: "" })}
            >
              +
            </Button>
          </div>
        )}
      </FieldArray>
    </FieldWrapper>
  );
};

export default CiVariables;
