import * as React from "react";
import { Typography, makeStyles, Grid, Container } from "@material-ui/core";
import LoginButton from "src/components/Header/LoginButton";

const Main: React.FC<{}> = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainBG}>
      <Container>
        <Grid
          className={classes.iner}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Typography
            variant="h1"
            component="h2"
            align="center"
            style={{ color: "white" }}
          >
            Разверни свой сайт в один клик
          </Typography>
          <br />
          <Typography
            color="textSecondary"
            variant="h6"
            style={{ color: "white" }}
          >
            Front Up — Облачная платформа для автоматизации доставки,
            развертывания и хостинга веб сервисов в России
          </Typography>
          <br />
          <LoginButton color="secondary" buttonVariant="contained" />
        </Grid>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  mainBG: {
    position: "relative",
    minHeight: "80vh",
    background:
      "linear-gradient(296.57deg, #00A8DE 0%, #0F86C8 8%, #2259AA 17.75%, #2E3D98 38.06%, #333391 44.83%, #3F3190 50.56%, #5E2C8F 64.1%, #90238C 79.21%, #D51789 89.1%, #E91388 95.87%, #FDE92B 100%);",
  },
  iner: {
    position: "relative",
    zIndex: 300,
    minHeight: "80vh",
  },
}));

export default Main;
