import { AxiosInstance, AxiosResponse } from "axios";
import {
  ProjectCardType,
  RepositoryItem,
  User,
  ProjectCreateData,
  ProjectType,
  Build,
  BuildPreview,
} from "src/types";

type Response<T> = Promise<AxiosResponse<T>>;

class Api {
  public httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  createProject = (values: ProjectCreateData): Response<{ id: number }> => {
    return this.httpClient.post(
      "/front/projects",
      { ...values, projectType: undefined },
      {
        params: {
          type: values.projectType,
        },
      }
    );
  };

  updateProject = (
    id: number,
    data: Partial<ProjectType>
  ): Response<ProjectType> => {
    return this.httpClient.put(`/front/projects/${id}`, data, {
      params: {
        type: data.projectType,
      },
    });
  };

  setDomainName = (
    projectId: number,
    data: { domainName: string }
  ): Response<{}> => {
    return this.httpClient.post(
      `/front/projects/${projectId}/bind-domain`,
      data
    );
  };

  runRebuild = (projectId: number): Response<{ buildId: string }> => {
    return this.httpClient.post(`/front/projects/${projectId}/rebuild`);
  };

  getProjectsPreviews = (): Response<ProjectCardType[]> => {
    return this.httpClient.get("/front/projects");
  };

  getProject = (id: number): Response<ProjectType> => {
    return this.httpClient.get(`/front/projects/${id}`);
  };

  deleteProject = (projectId: string | number): Response<{ id: string }> => {
    return this.httpClient.delete(`/front/projects/${projectId}`);
  };

  getRepositoryBranches = (id: string): Response<string[]> => {
    return this.httpClient.get(`/repositories/user/branches`, {
      params: { repoId: id },
    });
  };

  getProjectsBuilds = (projectId: number): Response<BuildPreview[]> => {
    return this.httpClient.get(`/front/projects/${projectId}/builds`);
  };

  getBuild = (projectId: string, buildId: string): Response<Build> => {
    return this.httpClient.get(
      `/front/projects/${projectId}/builds/${buildId}`
    );
  };

  cancelBuild = (projectId: string, buildId: string): Response<void> => {
    return this.httpClient.post(
      `/front/projects/${projectId}/builds/${buildId}/cancel`
    );
  };

  getUserRepositoryList = (): Response<RepositoryItem[]> => {
    return this.httpClient.get("/repositories");
  };

  getUser = (): Response<User> => {
    return this.httpClient.get("/front/user");
  };

  logout = (): Response<{}> => {
    return this.httpClient.get("/logout");
  };
}

export default Api;
