import * as React from "react";
import { CardContent, Typography } from "@material-ui/core";
import { ProjectCardType } from "src/types";
import { formatDistance } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  cardHeader: {
    marginBottom: "40px",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    padding: "16px 24px 20px 24px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "&>*": {
      display: "block",
    },
  },
});

function ProjectData(props: ProjectCardType) {
  const classes = useStyles();

  return (
    <CardContent className={classes.cardContent}>
      <div className={classes.cardHeader}>
        <Typography variant="h5">{props.name}</Typography>
      </div>
      <div className={classes.footer}>
        <Typography variant="caption">{props.gitRepositoryId}</Typography>
        <Typography variant="caption">
          {formatDistance(
            new Date(),
            new Date(props.creationDate) || new Date()
          )}
        </Typography>
      </div>
    </CardContent>
  );
}

export default ProjectData;
