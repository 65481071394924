import React from "react";
import Button from "src/components/Button";
import { RootState } from "src/store";
import { connect, ConnectedProps } from "react-redux";
import { ButtonProps } from "@material-ui/core/Button";

interface Props extends PropsFromRedux {
  color?: ButtonProps["color"];
  buttonVariant?: ButtonProps["variant"];
}

const LoginButton: React.FC<Props> = (props) => {
  if (props.user) return null;

  return (
    <div>
      <a
        href={
          process.env.REACT_APP_API_BASE_URL + "/oauth2/authorization/github"
        }
      >
        <Button
          variant={props.buttonVariant || "outlined"}
          color={props.color || "primary"}
        >
          Войти
        </Button>
      </a>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.userStore.user,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LoginButton);
