import * as React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import SettingsForm from "../../forms/domainNameForm";
import Typography from "@material-ui/core/Typography";
import Button from "../Button";
import Api from "../../api";
import { navigate } from "@reach/router";
import UpdateProjectForm from "../../forms/updateProject";
import { ProjectType } from "../../types";

interface ProjectSettingsProps {
  id: number;
}

const ProjectSettings: React.FC<ProjectSettingsProps> = (props) => {
  const handleDelete = async () => {
    const isConfirm = window.confirm("Вы хотите удалить проект?");
    if (!isConfirm) return;
    await Api.deleteProject(props.id);
    await navigate(`/`);
  };

  const [data, setData] = React.useState<ProjectType>();

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await Api.getProject(props.id);
      setData(res.data);
    };
    fetchData();
  }, [props.id]); // Перезапускать эффект только если id поменялся

  if (!data) return null;

  return (
    <Grid container direction="column">
      <SettingsForm
        projectId={props.id}
        domainName={data.customDomainName}
      ></SettingsForm>
      <br />
      <UpdateProjectForm project={data}></UpdateProjectForm>
      <br />
      <Card>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignContent="center"
          >
            <Typography variant="overline">Вы можете удалить проект</Typography>
            <Button variant="outlined" color="secondary" onClick={handleDelete}>
              Удалить проект
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ProjectSettings;
