import React from "react";
import { Form, FormRenderProps } from "react-final-form";
import Api from "../../api";
import TextField from "../../fields/textField/textField";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import formValidation from "./domainNameFormValidator";
import ButtonComponent from "../../components/Button";
import { makeStyles } from "@material-ui/core/styles";

type FormValues = { domainName: string };

const DomainNameForm = (props: { projectId: number; domainName?: string }) => {
  const onSubmit = async (data: FormValues) => {
    await Api.setDomainName(props.projectId, data);
  };

  const classes = useStyles();

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Card className={classes.welcomeCard}>
          <CardContent>
            <Typography>
              Тут можно указать домен своего проекта. Пропишите в A DNS записи
              вашего сайта наш IP адрес - 51.250.4.231
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item>
        <Form
          onSubmit={onSubmit}
          validate={(formValues) => formValidation.validateForm(formValues)}
          initialValues={{ domainName: props.domainName }}
        >
          {(formData: FormRenderProps<FormValues, {}>) => (
            <form onSubmit={formData.handleSubmit}>
              <Grid item container direction="column" spacing={2}>
                <Grid item>
                  <TextField
                    name={"domainName"}
                    placeholder="Доменное имя"
                    label="Доменное имя"
                  />
                </Grid>
                <Grid item className={classes.sendButton}>
                  <ButtonComponent
                    variant="outlined"
                    color="secondary"
                    type="submit"
                    disabled={
                      formData.hasValidationErrors || formData.submitting
                    }
                  >
                    Отправить
                  </ButtonComponent>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </Grid>
    </Grid>
  );
};

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  sendButton: {
    alignSelf: "flex-end",
  },
  welcomeCard: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    boxShadow: "none",
  },
}));

export default DomainNameForm;
