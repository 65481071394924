import React from "react";

import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Field, FormRenderProps } from "react-final-form";
import TextField from "../../fields/textField";
import SelectComponent from "../../fields/selectField";
import ButtonComponent from "../../components/Button";
// import CiVariables from "./ciVariables";
import { makeStyles } from "@material-ui/core/styles";
import { FormValues } from "./createProjectForm";
import { ERRORS_TYPES, PROJECT_FIELDS_NAMES } from "../../constants";
import { FormHelperText } from "@material-ui/core";

import { FrontUpErrors, ProjectLimitError } from "../../types";
import CiVariables from "./ciVariables";

export const SecondPage = (props: {
  formData: FormRenderProps<FormValues, {}>;
  buttonText: string | void;
  settings: { renderName?: boolean };
}) => {
  const submitErrors = props.formData.submitErrors as FrontUpErrors;
  const classes = useStyles();
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Card className={classes.welcomeCard}>
          <CardContent>
            <Typography>
              Привет, это только альфа версия нашего продукта. Много уже
              сделано, но еще больше впереди. Сейчас ты можешь создать только 3
              проекта. Будем рады, если ты напишешь в чат -{" "}
              <a target="_blank" href="https://t.me/frontupchat">
                <Typography component="span" color="primary">
                  t.me/frontupchat
                </Typography>
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item>
        {props.settings.renderName ? (
          <TextField
            name="name"
            caption="уникальное имя проекта"
            placeholder="my-awesome-project"
            label={PROJECT_FIELDS_NAMES.name}
            formErrors={submitErrors}
          />
        ) : null}
      </Grid>
      <Grid item>
        <Field
          name="gitBranch"
          label="branch"
          caption="ветка репозитория, используемая для сборки фронтенда"
          component={SelectComponent}
          placeholder="master"
          getValues={mapBranches}
          formErrors={submitErrors}
        ></Field>
      </Grid>
      <Grid item>
        <TextField
          label={PROJECT_FIELDS_NAMES.buildCommand}
          caption="команда, необходимая для сборки фронтенда<br/>если вашему фронтенду не требуется сборка, оставьте это поле пустым"
          name="buildCommand"
          placeholder="npm run build"
          formErrors={submitErrors}
        />
      </Grid>
      <Grid item>
        <TextField
          label={PROJECT_FIELDS_NAMES.pathToBuild}
          caption="относительный путь до директории, содержащей сгенерированные ресурсы для развертывания фронтендa, эта директория должна содержать index.html<br/>если требуется использовать корневую директорию проекта, оставьте это поле пустым"
          name="pathToBuild"
          placeholder="build"
          formErrors={submitErrors}
        />
      </Grid>
      <Grid item>
        <Field
          name="nodeVersion"
          label={PROJECT_FIELDS_NAMES.nodeVersion}
          caption="версия node js используемая в проекте, если не знаете, оставьте поле пустым. Мы будем использовать актуальную LTS версию"
          component={SelectComponent}
          placeholder="16"
          getValues={mapVersions}
        ></Field>
      </Grid>
      <Grid item>
        <CiVariables name="environments" />
      </Grid>
      <Grid item>
        <FormHelperText error>{renderServerError(submitErrors)}</FormHelperText>
      </Grid>
      <Grid item className={classes.sendButton}>
        <ButtonComponent
          variant="outlined"
          color="secondary"
          type="submit"
          disabled={
            props.formData.hasValidationErrors || props.formData.submitting
          }
        >
          {props.buttonText || "Развернуть проект"}
        </ButtonComponent>
      </Grid>
    </Grid>
  );
};

const mapBranches = () => {
  const branches: string[] = JSON.parse(
    window.localStorage.getItem("branches")
  );
  return branches || [];
};

const mapVersions = () => ["12", "14", "16"];

const renderServerError = (submitError: FrontUpErrors | void): string => {
  if (!submitError) return "";

  if (submitError.type === ERRORS_TYPES.PROJECT_LIMIT) {
    const error = submitError as ProjectLimitError;
    return `Вы достигли ограничения в ${error.options?.limit} проекта`;
  }

  if (submitError.type === ERRORS_TYPES.VALIDATION_ERROR) {
    return "";
  }

  return "Произошла ошибка";
};

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  sendButton: {
    alignSelf: "flex-end",
  },
  welcomeCard: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    boxShadow: "none",
  },
}));
