import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4263EB",
      light: "#F9FAFC",
      contrastText: "white",
    },
    secondary: {
      main: "#E91388",
    },
    text: {
      primary: "#1b1b24",
    },
  },
});

theme.typography.h1 = {
  ...theme.typography.h1,
  fontWeight: 700,
};
//
theme.typography.button = {
  fontSize: "16px",
};
//
theme.typography.h5 = {
  ...theme.typography.h5,
  fontWeight: 500,
  // textTransform: "capitalize",
  // fontSize: "24px",
};
//
theme.typography.caption = {
  color: "#4D4D4D",
  fontSize: "16px",
};

theme = responsiveFontSizes(theme);

export default theme;
