import React from "react";
import Button from "src/components/Button";
import { RootState } from "src/store";
import { connect, ConnectedProps } from "react-redux";
import { ButtonProps } from "@material-ui/core/Button";
import { actions as userActions } from "src/store/User";

interface Props extends PropsFromRedux {
  color?: ButtonProps["color"];
  buttonVariant?: ButtonProps["variant"];
}

const LogoutButton: React.FC<Props> = (props) => {
  if (!props.user) return null;
  const handleClick = () => {
    props.logout();
  };

  return (
    <div>
      <Button
        variant={props.buttonVariant || "outlined"}
        color={props.color || "primary"}
        onClick={handleClick}
      >
        Выйти
      </Button>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.userStore.user,
});

const mapActionsToProps = {
  logout: userActions.logout,
};

const connector = connect(mapStateToProps, mapActionsToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LogoutButton);
