import React from "react";
import CreateProjectForm from "../../forms/createProjectForm";
import { Container } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";

interface CreateNewProjectProps extends RouteComponentProps {
  //
}

const CreateNewProject: React.FC<CreateNewProjectProps> = () => {
  return (
    <Container>
      <CreateProjectForm></CreateProjectForm>
    </Container>
  );
};

export default CreateNewProject;
