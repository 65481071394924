import React from "react";
import { Link as NavigationLink } from "@reach/router";
import { Link as StyledLink, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

type LinkProps = {
  to: string;
};

const Link: React.FC<LinkProps> = (props) => {
  const classes = useStyles();
  return (
    <StyledLink
      component={NavigationLink}
      to={props.to}
      className={classes.root}
    >
      <Typography>{props.children}</Typography>
    </StyledLink>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export default Link;
