import React from "react";
import Link from "./Link";
import Button from "./Button";

const NewButton: React.FC<{}> = (props) => {
  return (
    <Link to="/new-project">
      <Button variant="outlined" color="primary">
        {props.children || "Создать"}
      </Button>
    </Link>
  );
};

export default NewButton;
