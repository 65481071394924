import React, { Component } from "react";
import Api from "src/api";
import { Form, FormRenderProps } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { formValidation } from "../validators";
import { ProjectType } from "../../types";
import { PROJECT_TYPE_NAMES } from "../../constants";
import { SecondPage } from "../createProjectForm/secondPage";
import { navigate } from "@reach/router";

type UpdateProjectFormProps = {
  project: ProjectType;
};

export type FormValues = {
  repositoryLink: string;
  name: string;
  buildCommand?: string;
  pathToBuild?: string;
  repositoryCloneGitUrl: string;
  gitBranch: string;
  nodeVersion?: string;
  projectType: keyof typeof PROJECT_TYPE_NAMES;
  environments: { key: string; value: string }[];
};

type S = {
  projectData: Partial<ProjectType>;
};

class UpdateProjectForm extends Component<UpdateProjectFormProps, S> {
  state = {
    projectData: {},
  };

  async componentDidMount() {
    const res = await Api.getRepositoryBranches(
      this.props.project.gitRepositoryId
    );

    window.localStorage.setItem("branches", JSON.stringify(res?.data || []));
  }

  handleSubmit = async (values: FormValues) => {
    const projectType: ProjectType["projectType"] = this.getProjectType(
      values.buildCommand
    );

    const data = {
      buildCommand: values.buildCommand,
      pathToBuild: values.pathToBuild,
      gitBranch: values.gitBranch,
      projectType: projectType,
      nodeVersion: values.nodeVersion,
      environments: values.environments?.reduce(
        (obj, item) => ({ ...obj, [item.key]: item.value }),
        {}
      ),
    };

    await Api.updateProject(this.props.project.id, data);
    alert("успешно обновленно");
    setTimeout(
      async () => await navigate(`/project/${this.props.project.id}`),
      500
    );
  };

  getProjectType = (buildCommand: string) => {
    if (buildCommand?.trim()) {
      return "NODEJS";
    }
    return "STATIC_SITE";
  };

  _validate = (values: FormValues) => {
    return formValidation.validateForm(values);
  };

  public render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        mutators={{ ...arrayMutators }}
        validate={this._validate}
        initialValues={this.props.project}
      >
        {(formData: FormRenderProps<FormValues, {}>) => (
          <form onSubmit={formData.handleSubmit}>
            <SecondPage
              formData={formData}
              buttonText={"Обновить"}
              settings={{}}
            />
          </form>
        )}
      </Form>
    );
  }
}

export default UpdateProjectForm;
