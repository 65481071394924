import React from "react";
import { Build as BuildType } from "src/types";
import Api from "../../api";
import { RouteComponentProps } from "@reach/router";
import TerminalRenderer from "./Terminal/Terminal";
import ProjectWrapper from "../../components/projectWrapper";
import BuildHeader from "./buildHeader";

type Props = RouteComponentProps<{
  buildId: string;
  projectId: string;
}>;

export type State = {
  build: BuildType;
};

class Build extends React.Component<Props, State> {
  state = {
    build: null as null | BuildType,
  };

  timer: NodeJS.Timeout;

  async componentDidMount() {
    this.timer = setInterval(this._getBuild, 5000);
    await this._getBuild();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  _getBuild = async () => {
    const res = await Api.getBuild(this.props.projectId, this.props.buildId);

    this.setState({
      build: res.data,
    });
  };

  render() {
    return (
      <ProjectWrapper id={+this.props.projectId}>
        {this._renderBuild()}
      </ProjectWrapper>
    );
  }

  _renderBuild = () => {
    const { build } = this.state;

    if (!build) return null;

    return (
      <div>
        <br />
        <br />
        <br />
        <BuildHeader
          build={build}
          buildId={this.props.buildId}
          projectId={this.props.projectId}
        />
        <br />

        <TerminalRenderer logs={build.logs} />
      </div>
    );
  };
}

export default Build;
